/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    span: "span",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "YelowSoft always puts its customers’ needs as a priority. Our ", React.createElement(_components.a, {
    href: "/taxi-dispatch-software/"
  }, "taxi dispatch solution"), " is very well crafted and advanced. Over the years we have come up with various updates to make our product improved, better and according to the client’s requirements."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 56.49999999999999%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACB0lEQVR42m2RSWhTQRjHA569iBfx5sWLNw/Si1ZQEaLkpBS0LoiigiAqaUApYlERClZcoNp6KpRS5YHEtKE2tbhiXAqlaRLjq1nfe8lbZ31rxmkbK5X8+WBm/jM/vmVC9l85LfEtJYQHWTPXHtjrFVpdKKUIIQgBgBQRFyIAkc/9FRMBAEzTBMCCEGKM/4e5+F1D1YEuSgv3FlPdVJ1GEOi6rmlaQ9WkuirXVX5sA/OSgKWrZtOp3K8lQtmxDe63LQTWIbY5vCSruaqarzY0w+RVtslMCCKUeSCF8sfVH+epGE3PfVWKogVxUSPlhiEqhmzA9j37vr8kg5k3b9+96g3cGlTyiUsnhvtuPHr6RLMkCbKK4UgGppSsgwmlLPDSmd+JrDcy8uzKye2seNb4cPpjJBLtH98Uji3M3KyXE6pVRhQC7K5+RAvmM2CMXb4aFQSh9KJHEbbmXm7Lju84cG30dt+pwwf3fRrYSCc3zwsdteRRKzPGk7VgXnAQBKVSKRw+9HzwwZnIhTvdHZ2dex9fP3Ksf3b41sWBc/vZ567CaNfOXbuHYnvYfC8m7j+42WwWCoXk5MT79FxP7O7sdGpqKrmYzQ3Fv3/JSA8HJ2yPQeLG468LYtFnjCP2cuDWwNwV+Z7j+44feLwLz7NdCinBmCBKoGMvt+Y5tlQtVWqyofx0rF9/AI4aMbb990YpAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"recurring-ride-feature-main\"\n        title=\"\"\n        data-src=\"/static/cc188e6612a44112c801050f9a602106/78d47/recurring-ride-feature-main.png\"\n        data-srcset=\"/static/cc188e6612a44112c801050f9a602106/56d15/recurring-ride-feature-main.png 200w,\n/static/cc188e6612a44112c801050f9a602106/d9f49/recurring-ride-feature-main.png 400w,\n/static/cc188e6612a44112c801050f9a602106/78d47/recurring-ride-feature-main.png 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Going forward with the same we have another feature update in the form of Recurring Ride."), "\n", React.createElement(_components.p, null, "Before moving further, let us first understand,"), "\n", React.createElement(_components.h2, {
    id: "why-was-there-an-update-required"
  }, "Why was there an update required?"), "\n", React.createElement(_components.p, null, "Today, companies go out of their way to show how much they care for their employees. Great benefits, a relaxed working environment, numerous perks, and so on. One of the major benefits that a lot of companies are providing is the commute and transport, either by ", React.createElement(_components.a, {
    href: "https://www.thehrdirector.com/business-news/flexible-agile-working/companies-covering-commuting-cost125/",
    target: "_blank",
    rel: "nofollow"
  }, "covering the cost"), " or by providing taxi service for their employees."), "\n", React.createElement(_components.p, null, "Specifically, in the case of corporate companies, they want their employees to have the best experience. Every employee gets a ride to and from work. They have tie-ups with various companies providing taxi services for their employees where they can book a ride every day whenever they require it."), "\n", React.createElement(_components.h2, {
    id: "some-of-the-challenges-that-the-corporate-clients-faced-were"
  }, "Some of the challenges that the corporate clients faced were:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Employees have to manually schedule/book a ride every day for their commute."), "\n", React.createElement(_components.li, null, "For employees with a month, six months, or a year of commute, it becomes a tedious task to book a ride every day."), "\n", React.createElement(_components.li, null, "If an employee forgets to book, they will wait without realizing that the ride has not been assigned."), "\n", React.createElement(_components.li, null, "If an employee tries to book a ride when the service is required, there is a possibility that the dispatcher is not able to find available drivers to assign at such short notice."), "\n"), "\n", React.createElement(_components.p, null, "This was the reason we came up with this solution of Recurring Ride. After extensive research and dialogues with our customers, we found this to be a very essential feature. Keeping their requirements, usage, and preferences in mind, we have updated this feature for the ease and convenience of our users."), "\n", React.createElement(_components.h2, {
    id: "what-is-a-recurring-ride"
  }, "What is a Recurring Ride?"), "\n", React.createElement(_components.p, null, "A Recurring feature is where once the customer books a ride, they can get the same service again without manually booking it every day."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 55.99999999999999%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9ElEQVR42o1Ry07DMBDsP0cKOdJzvox+R1KJIhRq4l17X2adQimvhJEPI2tnZzzepRvknEMIwzAcf2Acx9PplL5id2UAUEp5OBzu9/uu69q2vVvQLWiapu/7GKMb/CJ+X4FpTuIhRMRMmZmIdIHzP52vyRETs5QFxAKYzcy5r9sQY8rPAWZkqfPFDV3PolWsuiZ2z4A8IyXiSGofYie2KQbECWSKFCKcUTKrml1sLylWYyM+vuDTLInqCkiCWd2wLE8QWRX7L5xnPE44vYL3XQsT80WitTG/2SiMKKt5Wh+vh1gzefdi//kqRIwRboH4yb+J3wAJL3H4uTU3zwAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"recurring-ride-feature-ride\"\n        title=\"\"\n        data-src=\"/static/4a3e91a2682b6d8a96c91e43816c93cb/78d47/recurring-ride-feature-ride.png\"\n        data-srcset=\"/static/4a3e91a2682b6d8a96c91e43816c93cb/56d15/recurring-ride-feature-ride.png 200w,\n/static/4a3e91a2682b6d8a96c91e43816c93cb/d9f49/recurring-ride-feature-ride.png 400w,\n/static/4a3e91a2682b6d8a96c91e43816c93cb/78d47/recurring-ride-feature-ride.png 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Let us say Company A has a tie-up with the taxi service and needs it for their employees. The employees only have to book a ride for their day-to-day usage. Now, suppose Employee 1 needs a ride for 6 months. Earlier, he or she would have to book a taxi every day after which the dispatch service would assign a ride to them."), "\n", React.createElement(_components.p, null, "In the new feature of Recurring Ride, the employee just has to book a ride once and select the days that they want to book for. The dispatcher or admin will automatically assign a taxi to them on their pre-decided days. The employee has to no longer go through the hassle of selecting and booking their commute every day."), "\n", React.createElement(_components.h2, {
    id: "what-is-included-in-the-recurring-ride"
  }, "What is included in the Recurring Ride?"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Users can book a ride and select the intervals at which they want their ride."), "\n", React.createElement(_components.li, null, "Recurring feature allows you to book daily, and weekly."), "\n", React.createElement(_components.li, null, "Every time after the ride is completed, the next recurring ride is generated and the dispatcher assigns a taxi to them."), "\n", React.createElement(_components.li, null, "Users can also modify their booking with changes in days or weeks and it will be reflected from their next ride."), "\n"), "\n", React.createElement(_components.h2, {
    id: "additional-feature-added-save-the-location"
  }, "Additional feature added: Save the Location"), "\n", React.createElement(_components.p, null, "Alongside recurring feature, we have also added Save the location."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "This feature allows the user to save their pick-up and drop-off locations."), "\n", React.createElement(_components.li, null, "There are 3 categories i.e. Home, Work, and Others in which they can categorize their saved addresses."), "\n", React.createElement(_components.li, null, "Users can use the pre-saved location for new bookings without having to enter the address each time they are booking a new ride."), "\n"), "\n", React.createElement(_components.h2, {
    id: "why-recurring-ride-is-a-great-feature-for-corporates"
  }, "Why Recurring Ride is a great feature for corporates?"), "\n", React.createElement(_components.p, null, "Corporate clients have tie-ups with taxi services to provide a smooth, hassle-free commute to their employees. The automated service makes it easier for their employees as they have one less thing to worry about every morning when going to work. But, the ride service is supposed to save their time."), "\n", React.createElement(_components.p, null, "The recurring ride feature saves their time as every morning, their commute is already booked and their ride is there. Many corporate clients have loved this feature and were eager for it to be launched."), "\n", React.createElement(_components.h2, {
    id: "conclusion"
  }, "Conclusion"), "\n", React.createElement(_components.p, null, "YelowSoft always aims to provide the best of service to its customers and this was just one of the newest solutions added to our always improving service. We constantly strive to stay updated and to keep improving ourselves. Many more ", React.createElement(_components.a, {
    href: "/blog/yelowsoft-releases-new-version-update/"
  }, "product updates"), " are lined up. Stay tuned to know more!"), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/contact/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 700px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 28.500000000000004%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABn0lEQVR42j2RbUtTcRiH9x1ExCTFBxRT0bDyKQY1N3XqVhFSfhG/g5+hF0Fv1MmmZzsunE724HxobYFGsCxYnZ12tCF1oj2csasbhF5c/O//m4vf775tBNsh3E1V7eOveodyeIjq7gjVyCiV/QdUohOUo1NUYnaqiUeUEw7BSTnp4k/cRS3lglMhMwvnbmyNrduw08l1oBfd14/mG0bzj1Lw30ffHkNXJskHHvJdsVMMP0ZTHeRVJ9pbF1p4GjM+A+9ElpkXoRcb/lugdEjKHtjr59rfSXGjmytlACM4SGlnmB+hu+jqPUqRcS4jUyIe59eBHbIL8F5IiyzrhY9LItxoAUnZUHtohNqxPqxg6XHqnzap57awvqrUL4JYFyHqX2TOKVjGEdbnVRon0yL0CIsifCLCFyJ80wy+NtjukqStlM5eUfj2E2M/ipE4pJjOUDxJY8SS8k9hJA8p5C8p5dZkdw6p6rkh+1QqvxTh6yZYb4WAHCfURU0d4PeuE/PAjRlblB15MJPPhOeYqSXM42V5l6kduaXq3E26jPe/8B/2gWvdfJ7iJAAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"recurring-ride-cta-1\"\n        title=\"\"\n        data-src=\"/static/fc22e2ae389adbe798b7be40165d30e9/a27c6/recurring-ride-cta-1.png\"\n        data-srcset=\"/static/fc22e2ae389adbe798b7be40165d30e9/56d15/recurring-ride-cta-1.png 200w,\n/static/fc22e2ae389adbe798b7be40165d30e9/d9f49/recurring-ride-cta-1.png 400w,\n/static/fc22e2ae389adbe798b7be40165d30e9/a27c6/recurring-ride-cta-1.png 700w\"\n        sizes=\"(max-width: 700px) 100vw, 700px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
